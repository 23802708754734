<template>
  <div id="my-bag">
    <div v-if="loadingBag" class="container-max-width">
      <skeleton-bag />
    </div>
    <div v-else class="container-max-width">
      <div
        v-if="productsCart && productsCart.length > 0"
        class="container-my-bag"
      >
        <div class="col-12 col-md-8">
          <div class="col-12 px-3 products-bag">
            <div class="bag-title">
              <h3>Tu bolsa</h3>
              <p>
                {{ productsCart.length }}
                {{ productsCart.length == 1 ? 'Producto' : 'Productos' }}
              </p>
            </div>
            <div class="alert-stock-products-bag">
              <p class="mb-0">
                Ten en cuenta que los productos añadidos a la bolsa no se
                reservan. Finaliza tu compra para hacerlos tuyos.
              </p>
            </div>
            <div class="d-block d-md-none mb-4">
              <div class="data-bag-price justify-content-center">
                <p class="bold" style="font-size: 1.5rem">
                  Total: ${{
                    new Intl.NumberFormat('de-DE').format(
                      dataCart.carrito_total,
                    )
                  }}
                </p>
              </div>
              <button
                class="btn-primary btn-finalice-bag"
                type=""
                @click="$router.push({ name: 'Payment' })"
              >
                Finalizar Compra
              </button>
            </div>
            <div class="container-products-bag">
              <products-bag
                v-for="(product, index) in productGroup"
                :key="index"
                :product="product"
              />
            </div>
          </div>
          <strategy-bag
            v-for="(item, index) in productStrategy"
            :key="index"
            :product-strategy="item"
            class="col-12 px-3"
          />
          <div
            class="mt-5 px-3 d-bock d-md-none"
            style="background-color: #FAFAFA;"
          >
            <detail-bag />
          </div>
          <div v-if="productFeatured" class="col-12 box-recommended">
            <div id="carousel-vpt" class="product-recommended">
              <h3 class="col-7 col-xl-12" style="font-size: 24px">
                Completa tu compra /
              </h3>
              <div>
                <div
                  :key="
                    productFeatured
                      ? keyCarousel +
                        '-' +
                        productFeatured.length +
                        'Carousel...'
                      : keyCarousel + 'cargando...'
                  "
                >
                  <carousel
                    class="col-12 pb-4 px-0 position-relative owl-carousel-container mb-4"
                    :dots="false"
                    :lazy-load="true"
                    :nav="false"
                    :responsive="{
                      0: { items: 1, nav: false, stagePadding: 0 },
                      500: { items: 3, nav: false, stagePadding: 0 },
                      1200: { items: 4, nav: false, stagePadding: 0 },
                    }"
                  >
                    <template slot="prev"
                      ><span class="prev nav-button">
                        <img
                          style="transform: rotate(180deg)"
                          src="@/assets/icons/arrow-btn-cupo.svg"
                          alt="left arrow carousel"
                        /> </span
                    ></template>
                    <div
                      v-for="(product, index) in productFeatured"
                      :key="index + product.producto_sku"
                    >
                      <card-product-hover
                        :product="product"
                        :show-modal="true"
                        :opt2="true"
                        :vitrina-id="'vitrina-vpt'"
                        :vitrina-name="'Completa tu compra'"
                      />
                    </div>
                    <template slot="next"
                      ><span class="next nav-button">
                        <img
                          src="@/assets/icons/arrow-btn-cupo.svg"
                          alt="right arrow carousel"
                        /> </span
                    ></template>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 d-none d-md-block" style="background-color: #FAFAFA;">
          <detail-bag />
        </div>
      </div>
      <div v-else>
        <div class="container-bag-empty">
          <div class="text-center">
            <h3>Aún no tienes productos en tu bolsa de compras :(</h3>
            <p>
              Cuando agregues productos a tu bolsa los podrás ver en este
              espacio.
            </p>
            <router-link :to="{ name: 'Store' }">
              <button class="btn-primary px-4 fs-6">Quiero comprar</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <add-to-cart />
    <modal-message
      :key="msgErrorAddProduct"
      :content="msgErrorAddProduct"
      :title="'Atención'"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import carousel from 'v-owl-carousel'
import { GA4ViewCart, GA4ViewItemList } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
import { sendCriteoEventCriteo } from '@/criteo/events'

import DetailBag from '@/components/Bag/DetailBag.vue'
import ProductsBag from '@/components/Bag/ProductsBag.vue'
import StrategyBag from '@/components/Bag/StrategyBag.vue'
import SkeletonBag from '@/components/Bag/SkeletonBag.vue'
import AddToCart from '@/components/ProductDetail/AddToCart.vue'
import ModalMessage from '@/components/Templates/ModalMessage.vue'
import CardProductHover from '@/components/Templates/CardProductHover.vue'

export default {
  components: {
    carousel,
    ProductsBag,
    CardProductHover,
    DetailBag,
    StrategyBag,
    SkeletonBag,
    AddToCart,
    ModalMessage,
  },
  data() {
    return {
      showShipping: false,
      showVpt: false,
      keyCarousel: 1,
    }
  },
  computed: {
    ...mapState('cart', [
      'productsCart',
      'productFeatured',
      'dataCart',
      'productStrategy',
      'loadingBag',
      'msgErrorAddProduct',
    ]),
    ...mapState(['innerWidth']),
    getDataAnalytics() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          size: product.referencia.talla_id,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
    productGroup() {
      let products_group = []
      this.productsCart.filter(product => {
        if (
          !products_group.find(
            element =>
              element.producto_sku == product.producto_sku &&
              element.referencia.color_id == product.referencia.color_id &&
              element.referencia.talla.id == product.referencia.talla.id &&
              product.producto_aplica_estrategia == 0 &&
              element.producto_aplica_estrategia != 1 &&
              element.descuento_aplica_estrategia ==
                product.descuento_aplica_estrategia,
          ) ||
          product.producto_aplica_estrategia == 1
        ) {
          let dataTemp = product
          dataTemp.count_products = 1
          products_group.push(product)
        } else {
          products_group = products_group.filter(element => {
            if (
              element.producto_sku == product.producto_sku &&
              element.referencia.color_id == product.referencia.color_id &&
              element.referencia.talla.id == product.referencia.talla.id &&
              element.producto_aplica_estrategia == 0 &&
              element.descuento_aplica_estrategia ==
                product.descuento_aplica_estrategia
            ) {
              element.count_products += 1
            }
            return element
          })
        }
      })
      return products_group
    },
  },
  watch: {
    productsCart() {
      if (this.productsCart && this.productsCart.length > 0) {
        GA4ViewCart()
        sendCriteoEventCriteo([
          { event: 'viewBasket', item: this.getDataAnalytics },
        ])
      }
    },
    async msgErrorAddProduct() {
      if (this.msgErrorAddProduct) {
        await setTimeout(() => {
          this.$bvModal.show('modal-message')
        }, 100)
      }
    },
    productFeatured() {
      this.keyCarousel += this.keyCarousel
      if (this.productFeatured) {
        setTimeout(() => {
          this.listendScrollVPT()
        }, 500)
      }
    },
  },
  beforeCreate() {
    this.$store.state.layouts.footerLayoutDerek = 'footer-simple'
    this.$store.state.layouts.menuLayoutDerek = 'menu-simple'
  },
  created() {
    sendFBQEvent(this, 'PageView', {})
    this.$store.state.layouts.footerLayoutDerek = 'footer-simple'
    this.$store.state.layouts.menuLayoutDerek = 'menu-simple'
  },
  destroyed() {
    this.$store.state.layouts.footerLayoutDerek = 'footer-derek'
    this.$store.state.layouts.menuLayoutDerek = 'menu-base'
  },
  mounted() {
    this.$store.state.layouts.footerLayoutDerek = 'footer-simple'
    this.$store.state.layouts.menuLayoutDerek = 'menu-simple'
    document.title = 'Bolsa | DEREK Tienda Online'
    if (this.productsCart && this.productsCart.length > 0) {
      GA4ViewCart()
      sendCriteoEventCriteo([
        { event: 'viewBasket', item: this.getDataAnalytics },
      ])
    }
    window.addEventListener('scroll', this.listendScrollVPT)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.listendScrollVPT)
  },
  methods: {
    listendScrollVPT() {
      let vitrinaVPT = document.getElementById('carousel-vpt')
      if (
        vitrinaVPT &&
        !this.showVpt &&
        vitrinaVPT.getBoundingClientRect().top -
          (window.innerHeight - vitrinaVPT.offsetHeight / 2) <=
          0
      ) {
        this.showVpt = true
        GA4ViewItemList(
          this.productFeatured,
          'vitrina-vpt',
          'Completa tu compra',
        )
      }
    },
  },
}
</script>
