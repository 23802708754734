<template>
  <div>
    <div class="container-strategy-bag">
      <router-link
        :to="{
          name: 'ProductDetail',
          params: {
            product: productStrategy.url.replace('product-', ''),
            vitrinaId: 'bag-page-estrategy-cart',
            vitrinaName: 'productos de estrategia',
          },
        }"
        class="title-product-strategy"
      >
        <img
          class="img-strategy-bag"
          :src="productStrategy.referencias[indexRef].imagenes"
          alt="producto en oferta!"
        />
      </router-link>
      <div class="content-data-product-strategy d-block d-md-none">
        <router-link
          :to="{
            name: 'ProductDetail',
            params: {
              product: productStrategy.url.replace('product-', ''),
              vitrinaId: 'bag-page-products-cart',
              vitrinaName: 'Productos agregados al carrito',
            },
          }"
          class="title-product-strategy"
          >{{
            productStrategy.titulo
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}</router-link
        >
        <br />
        <span class="data-product-strategy">
          SKU: {{ productStrategy.producto_sku }} <br />
          Color: {{ productStrategy.referencias[indexRef].color_nombre }}
        </span>
        <div class="price-product-strategy">
          <p>
            {{
              productStrategy.precio == 0
                ? 'Gratis'
                : '$' +
                  new Intl.NumberFormat('de-DE').format(productStrategy.precio)
            }}
          </p>
          <del
            >${{
              new Intl.NumberFormat('de-DE').format(
                productStrategy.precio_antes,
              )
            }}</del
          >
        </div>
      </div>
      <div class="strategy-bag">
        <div class="content-data-product-strategy d-none d-md-block">
          <router-link
            :to="{
              name: 'ProductDetail',
              params: {
                product: productStrategy.url.replace('product-', ''),
                vitrinaId: 'bag-page-products-cart',
                vitrinaName: 'Productos agregados al carrito',
              },
            }"
            class="title-product-strategy"
            >{{
              productStrategy.titulo
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}</router-link
          >
          <br />
          <span class="data-product-strategy">
            SKU: {{ productStrategy.producto_sku }} <br />
            Color: {{ productStrategy.referencias[indexRef].color_nombre }}
            <br />
          </span>
          <div class="price-product-strategy">
            <p>
              {{
                productStrategy.precio == 0
                  ? 'Gratis'
                  : '$' +
                    new Intl.NumberFormat('de-DE').format(
                      productStrategy.precio,
                    )
              }}
            </p>
            <del
              >${{
                new Intl.NumberFormat('de-DE').format(
                  productStrategy.precio_antes,
                )
              }}</del
            >
          </div>
        </div>
        <div class="content-color-size-agg-strategy">
          <div class="container-color-strategy">
            <p
              class="title-color-size-strategy"
              :class="
                productStrategy.referencias[indexRef].tallas.length == 1 &&
                sizeSelected == 'UN'
                  ? 'mb-4'
                  : ''
              "
            >
              Talla:
              <strong>{{
                productStrategy.referencias[indexRef].tallas.length == 1 &&
                sizeSelected == 'UN'
                  ? productStrategy.referencias[indexRef].tallas[0].name
                  : ''
              }}</strong>
            </p>
            <div
              v-if="
                !(
                  productStrategy.referencias[indexRef].tallas.length == 1 &&
                  sizeSelected == 'UN'
                )
              "
              class="d-flex"
            >
              <div
                v-for="(size, index) in productStrategy.referencias[indexRef]
                  .tallas"
                :key="index + 'size'"
                class="d-flex pe-3"
                @click="sizeSelected = size.id"
              >
                <span
                  :style="
                    missingSize
                      ? `animation-name: missingSize; animation-duration: .5s;`
                      : ''
                  "
                  class="sizes-product"
                  :class="sizeSelected == size.id ? 'size-selected' : ''"
                  >{{ size.name }}</span
                >
              </div>
            </div>
          </div>
          <div class="container-sizes-strategy">
            <p class="title-color-size-strategy">Color:</p>
            <div class="d-flex">
              <div
                v-for="(color, index) in productStrategy.referencias"
                :key="index + 'colores'"
                class="d-flex pe-2"
                @click="
                  colorSelected = color.color_id
                  indexRef = index
                "
              >
                <div
                  class="contenedor-colors-product-detail"
                  :class="
                    colorSelected == color.color_id
                      ? 'color-selected-product-detail'
                      : ''
                  "
                >
                  <div
                    class="colors-product-detail"
                    :style="
                      'bacground-color:' +
                        color.color_hx +
                        ';background-image: url(' +
                        color.color_textura +
                        ')'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn-agg-strategy edit-data-perfil-button"
            @click="agregarProducto()"
          >
            <div v-if="!loading">
              <img src="@/assets/icons/shopping-bag.svg" alt="" /> Agregar a la
              bolsa
            </div>
            <div v-else class="d-flex justify-content-center">
              Cargando...
              <div class="loader" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
import { sendCriteoEventCriteo } from '@/criteo/events'
export default {
  props: {
    productStrategy: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      missingSize: false,
      loading: false,
      indexRef: 0,
      colorSelected: '',
      sizeSelected: '',
    }
  },
  computed: {
    ...mapState('cart', ['msgErrorAddProduct']),
    ...mapState('user', ['dataUser']),
  },
  mounted() {
    this.colorSelected = this.productStrategy.referencias[0].color_id
    if (
      this.productStrategy.referencias[this.indexRef].tallas.length == 1 &&
      this.productStrategy.referencias[this.indexRef].tallas[0].id == 'UN'
    ) {
      this.sizeSelected = this.productStrategy.referencias[
        this.indexRef
      ].tallas[0].id
    }
  },
  methods: {
    ...mapActions('cart', ['addToCart']),
    async agregarProducto() {
      if (this.sizeSelected) {
        // let elThis = this;
        this.loading = true
        let dataProductAddToCart = {
          size: this.sizeSelected,
          color: this.colorSelected,
          sku: this.productStrategy.producto_sku,
          estrategia_id: this.productStrategy.estrategia_id,
        }
        await this.addToCart(dataProductAddToCart).then(() => {
          if (this.msgErrorAddProduct == '') {
            sendFBQEvent(this, 'AddToCart', {
              product_id: this.productStrategy.producto_sku,
              value: this.productStrategy.precio,
              content_type: 'product',
              content_name:
                this.productStrategy.categoria_slug.toUpperCase() + ' DEREK',
              content_category: this.productStrategy.categoria_slug.toUpperCase(),
              content_ids:
                this.productStrategy.producto_sku +
                this.colorSelected +
                this.sizeSelected,
              currency: 'COP',
            })
            sendCriteoEventCriteo([
              { event: 'addToCart', item: [this.productStrategy.producto_sku] },
            ])
          }
          this.loading = false
        })
      } else {
        this.msgError = true
        this.missingSize = true
        await setTimeout(() => {
          this.missingSize = false
        }, 1000)
      }
    },
  },
}
</script>
