<template>
  <div class="item-product-bag">
    <router-link
      :to="{
        name: 'ProductDetail',
        params: {
          product: product.url.replace('product-', ''),
          talla: product.referencia.talla.id,
          vitrinaId: 'bag-page-products-cart',
          vitrinaName: 'Productos agregados al carrito',
        },
        query: { color: product.referencia.color_id },
      }"
    >
      <img
        style="aspect-ratio: 8/9;"
        :src="product.referencia.imagenes[0]"
        alt=""
      />
      <img
        v-if="product.producto_aplica_estrategia == 1"
        class="img-product-estrategia-bag-movil"
        src="@/assets/icons/gift.svg"
        alt=""
      />
    </router-link>
    <div class="content-product-bag">
      <div class="text-product-bag">
        <router-link
          style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;"
          :to="{
            name: 'ProductDetail',
            params: {
              product: product.url.replace('product-', ''),
              talla: product.referencia.talla.id,
              vitrinaId: 'bag-page-products-cart',
              vitrinaName: 'Productos agregados al carrito',
            },
            query: { color: product.referencia.color_id },
          }"
        >
          <p :style="product.status == 0 ? 'opacity: .4;' : ''">
            {{
              product.producto_titulo
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </p>
        </router-link>
        <p
          class="data-text-product-bag"
          :style="product.status == 0 ? 'opacity: .4' : ''"
        >
          SKU: {{ product.producto_sku }} <br />
          Talla: {{ product.referencia.talla.name }}
          <span class="mb-0 d-none d-md-block px-2">/</span
          ><br class="d-block d-md-none" />
          Color:
          {{
            product.referencia.color_nombre
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}
        </p>
        <div v-if="product.tags.length > 0" class="d-flex flex-wrap mb-2">
          <span
            v-for="(tag, index) in product.tags"
            :key="index"
            class="d-flex pt-1 me-2"
          >
            <label
              :style="
                `background-color: ${tag.color_fondo}; color: ${tag.color_texto}`
              "
              style="font-size: 14px; padding: .1rem .4rem; font-weight: bold;"
              >{{ tag.tag }}</label
            >
          </span>
        </div>
        <div
          v-if="product.dia_siniva == 1"
          class="price-text-product-bag text-center d-flex d-md-none mb-3"
        >
          <del v-if="product.status == 1 && dataCart.sin_iva == 1"
            >${{ new Intl.NumberFormat('de-DE').format(product.precio) }}</del
          >
          <p
            v-if="product.status == 1"
            class="text-end mt-2 ms-3"
            style="line-height: .8; font-size: 1.1rem;"
          >
            <b
              >${{
                dataCart.sin_iva == 1
                  ? new Intl.NumberFormat('de-DE').format(
                      product.producto_precio_siniva,
                    )
                  : new Intl.NumberFormat('de-DE').format(product.precio)
              }}</b
            >
            <br />
            <span v-if="dataCart.sin_iva == 1" style="font-size: 11px"
              >*Precio sin IVA</span
            >
          </p>
          <router-link
            v-if="product.status == 0"
            style="color: #0d6efd;"
            :to="{
              name: 'TiendaCategory',
              params: { category: product.categoria.toLowerCase() },
            }"
          >
            Ver sugerencias
          </router-link>
        </div>
        <div v-else-if="product.status == 1" class="d-flex d-md-none mb-1">
          <del
            v-if="product.status == 1 && product.precio_antes > 0"
            class="pe-2"
            style="color: #949494"
            >${{
              new Intl.NumberFormat('de-DE').format(
                product.precio_antes * product.count_products,
              )
            }}</del
          >
          <p class="price-movil-bag mt-0 pb-0">
            {{
              product.precio == 0
                ? 'Gratis'
                : '$' +
                  new Intl.NumberFormat('de-DE').format(
                    product.precio * product.count_products,
                  )
            }}
          </p>
        </div>
        <div
          v-if="product.producto_aplica_estrategia == 0 && product.status == 1"
          class="d-flex flex-wrap pb-2 pt-3"
        >
          <div class="box-count-product">
            <span class="less" @click="deleteOneProduct()">-</span>
            <span class="box-count">{{ product.count_products }}</span>
            <span class="more" @click="addProductOneProductToCart()">+</span>
          </div>
        </div>
        <div
          :class="
            product.status == 1 && product.referencia.tallas.length > 1
              ? 'mb-4'
              : ''
          "
        >
          <span
            v-if="product.status == 1 && product.referencia.tallas.length > 1"
            class="btn-edit-product-bag"
            @click="showEditSize = true"
            >Editar</span
          >
        </div>
        <span v-if="product.status == 0" class="out-of-stock"
          >Este producto está agotado</span
        >
        <router-link
          v-if="product.status == 0"
          class="d-flex d-md-none my-1"
          style="color: #0d6efd;"
          :to="{
            name: 'TiendaCategory',
            params: { category: product.categoria.toLowerCase() },
          }"
        >
          Ver sugerencias
        </router-link>
      </div>
      <div
        v-if="product.status == 1"
        class="divider position-relative d-none d-md-flex"
      >
        <img
          v-if="product.producto_aplica_estrategia == 1"
          title="Producto de promoción"
          class="img-product-estrategia-bag"
          src="@/assets/icons/gift.svg"
          alt=""
        />
      </div>
    </div>
    <div
      v-if="product.dia_siniva == 1"
      class="price-text-product-bag text-center d-none d-md-block"
    >
      <del v-if="product.status == 1 && dataCart.sin_iva == 1"
        >${{ new Intl.NumberFormat('de-DE').format(product.precio) }}</del
      >
      <p
        v-if="product.status == 1"
        class="text-end mt-2"
        style="line-height: .8; font-size: 1.1rem;"
      >
        <b
          >${{
            dataCart.sin_iva == 1
              ? new Intl.NumberFormat('de-DE').format(
                  product.producto_precio_siniva,
                )
              : new Intl.NumberFormat('de-DE').format(product.precio)
          }}</b
        >
        <br />
        <span v-if="dataCart.sin_iva == 1" style="font-size: 11px"
          >*Precio sin IVA</span
        >
      </p>
      <router-link
        v-if="product.status == 0"
        style="color: #0d6efd;"
        :to="{
          name: 'TiendaCategory',
          params: { category: product.categoria.toLowerCase() },
        }"
      >
        Ver sugerencias
      </router-link>
    </div>
    <div v-else class="price-text-product-bag text-center d-none d-md-block">
      <p v-if="product.status == 1">
        ${{
          new Intl.NumberFormat('de-DE').format(
            product.precio * product.count_products,
          )
        }}
      </p>
      <del v-if="product.status == 1 && product.precio_antes > 0"
        >${{
          new Intl.NumberFormat('de-DE').format(
            product.precio_antes * product.count_products,
          )
        }}</del
      >
      <router-link
        v-if="product.status == 0"
        style="color: #0d6efd;"
        :to="{
          name: 'TiendaCategory',
          params: { category: product.categoria.toLowerCase() },
        }"
      >
        Ver sugerencias
      </router-link>
    </div>
    <div class="delete-product-bag">
      <img
        v-if="!loading"
        src="@/assets/icons/delete-gray-icon.svg"
        alt="boton cerrar"
        @click="deleteProduct()"
      />
      <div v-else class="loader loader-delete" />
    </div>
    <div v-if="showEditSize" class="box-edit-size-product">
      <div class="container-edit-size-product">
        <div class="d-flex flex-wrap">
          <p class="mb-2 w-100 fs-6">Elige una talla:</p>
          <div
            v-for="(sizeProduct, index) in product.referencia.tallas"
            :key="index"
            class="d-flex pe-2"
          >
            <span
              :title="
                sizeProduct.stock == 0
                  ? 'Talla agotada'
                  : 'Talla: ' + sizeProduct.name
              "
              :style="
                missingSize
                  ? `animation-name: missingSize; animation-duration: .5s;`
                  : ''
              "
              class="sizes-product"
              :class="sizeProduct.id == changeSize ? 'size-selected' : ''"
              @click="changeSize = sizeProduct.id"
            >
              {{ sizeProduct.name }}
            </span>
          </div>
        </div>
        <div class="btn-save-edit-product-bag">
          <button class="btn-primary w-100 p-0" @click="editSize()">
            <div v-if="!loadingEdit">Guardar</div>
            <div v-else class="d-flex align-items-center">
              <div class="m-auto loader loader-delete" />
            </div>
          </button>
        </div>
      </div>
      <img
        src="@/assets/icons/delete-gray-icon.svg"
        alt="boton cerrar"
        @click="showEditSize = false"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { GA4AddToCart, GA4DelToCart } from '@/GA4/events'
import { sendCriteoEventCriteo } from '@/criteo/events'

export default {
  props: {
    product: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      showEditSize: false,
      missingSize: false,
      changeSize: null,
      loadingEdit: false,
    }
  },
  computed: {
    ...mapState('cart', ['dataCart']),
    ...mapState('user', ['dataUser']),
  },
  methods: {
    ...mapActions('cart', ['deleteCartProduct', 'addToCart']),
    async deleteProduct() {
      this.$store.state.loaderDerek = true
      this.loading = true
      let tempDelectProduct = this.product
      await this.deleteCartProduct({
        id_carrito_producto: this.product.id_carrito_producto,
        tipo: 1,
      }).then(() => {
        GA4DelToCart({
          item_name: tempDelectProduct.producto_titulo,
          item_id: tempDelectProduct.producto_sku,
          price: tempDelectProduct.precio,
          affiliation: 'Derek',
          item_brand: tempDelectProduct.marca,
          item_category: tempDelectProduct.categoria,
          item_category2: tempDelectProduct.subcategoria,
          item_variant: tempDelectProduct.referencia.color_id,
          quantity: tempDelectProduct.count_products,
        })
        this.$store.state.loaderDerek = false
        this.loading = false
      })
    },
    async deleteOneProduct() {
      let tempDelectProduct = this.product
      this.$store.state.loaderDerek = true
      await this.deleteCartProduct({
        id_carrito_producto: this.product.id_carrito_producto,
        tipo: 0,
      }).then(() => {
        GA4DelToCart({
          item_name: tempDelectProduct.producto_titulo,
          item_id: tempDelectProduct.producto_sku,
          price: tempDelectProduct.precio,
          affiliation: 'Derek',
          item_brand: tempDelectProduct.marca,
          item_category: tempDelectProduct.categoria,
          item_category2: tempDelectProduct.subcategoria,
          item_variant: tempDelectProduct.referencia.color_id,
          quantity: 1,
        })
        this.$store.state.loaderDerek = false
      })
    },
    async editSize() {
      if (this.changeSize) {
        if (this.product.referencia.talla.id != this.changeSize) {
          this.loadingEdit = true
          this.$store.state.cart.msgErrorAddProduct = ''
          let form = new FormData()
          form.append('token', window.localStorage.getItem('tokenCart'))
          form.append('talla_id', this.changeSize)
          form.append('id_carrito_producto', this.product.id_carrito_producto)
          await this.axios({
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cart/updateProduct',
            data: form,
          })
            .then(response => {
              this.$store.state.cart.dataCart = response.data.carrito
              this.$store.state.cart.productsCart = response.data.productos
            })
            .catch(e => {
              if (e.response.data.message) {
                this.$store.state.cart.msgErrorAddProduct =
                  e.response.data.message
              } else {
                let message = ''
                for (const property in e.response.data.errors) {
                  message = message + e.response.data.errors[property] + ' '
                  if (property == 'token') {
                    window.localStorage.removeItem('tokenCart')
                    this.$store.state.cart.productsCart = null
                    this.$store.state.cart.productStrategy = null
                  }
                }
                this.$store.state.cart.msgErrorAddProduct = message
              }
            })
            .finally(() => {
              this.loadingEdit = false
              this.showEditSize = false
            })
        } else {
          this.showEditSize = false
        }
      } else {
        this.missingSize = true
        await setTimeout(() => {
          this.missingSize = false
        }, 1000)
      }
    },
    async addProductOneProductToCart() {
      this.$store.state.loaderDerek = true
      let dataProductAddToCart = {
        size: this.product.referencia.talla.id,
        color: this.product.referencia.color_id,
        sku: this.product.producto_sku,
      }
      await this.addToCart(dataProductAddToCart).then(() => {
        GA4AddToCart(
          this.product.producto_sku,
          'bag_page_products_cart',
          'productos del carrito',
        )
        sendCriteoEventCriteo([
          { event: 'addToCart', item: [this.product.producto_sku] },
        ])
        this.$store.state.loaderDerek = false
      })
    },
  },
}
</script>
